import { Link, Routes, Route } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { getKriser } from '../krisData';
import Kris from './kris';

export default function Kriser() {
	const skulder = getKriser();

	return (
		<>
			<Navbar />
			<div>
				{/* <h1 className="title-h1">Tips från:</h1> */}
				<div className="pathgroup">
					{skulder.map(({ name, id }) => (
						// <div className="pathgroup__box">
						<Link className="pathgroup__box" to={id} key={id}>
							<div className="pathgroup__link"> {name}</div>
						</Link>
						// </div>
					))}
				</div>
				<Routes>
					<Route path=":skuldId/*" element={<Kris />} />
				</Routes>
			</div>
		</>
	);
}
