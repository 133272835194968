import { useParams, Routes, Route } from 'react-router-dom';
import Navbar from '../components/Navbar';
import SideNav from '../components/SideNav';
import { getKris } from '../krisData';
import QuestionKris from './questionKris';
import './skuld.scss';
import Profile from '../components/Profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function Kris() {
	const { skuldId } = useParams();
	const skuld = getKris(skuldId);
	//console.log(skuldId);
	//console.log(skuld);

	const arrow = <FontAwesomeIcon icon={faArrowRight} />;
	return (
		<>
			<Navbar />
			<div className="course__container">
				<div className="course">
					<Routes className="course__main">
						<Route path=":questionId" element={<QuestionKris />} />{' '}
						<Route
							index
							element={
								<main>
									{skuld.introVideo ? (
										<video
											className="course__video"
											src={skuld.introVideo}
											type="video/mp4"
											controls
											autoPlay
										></video>
									) : (
										// skuld.name
										<Profile
											name={skuld.personName}
											about={skuld.about}
											image={skuld.image}
										/>
									)}
									<div className="course__video-textbox">
										{skuld.next ? (
											<a
												className="course__video-textbox-link"
												href={skuld.next}
												key={skuld.id}
											>
												Nästa fråga {arrow}
											</a>
										) : (
											''
										)}
										<h2 className="course__video-textbox-h2">{skuld.topic}</h2>
										{/* <p className="course__video-textbox-p">{skuld.answer}</p> */}
										{skuld.answer.map((e) => (
											<div key={Math.random().toString()}>{e}</div>
										))}
									</div>
								</main>
							}
						/>
					</Routes>
					<SideNav
						title={skuld.name}
						questions={skuld.questions}
						id={skuld.key}
					/>
				</div>
			</div>
		</>
	);
}
//params använder url:en, url:en har vi bestämt i invoices.js

//TODO: fix udemy layout here, almost done
//TODO: load video from server done
//TODO: landing page when you arrive att eg malmö stad, prepared
//TODO: style question list, working on it
//TODO: when click on question scroll up, and load the video, not done
