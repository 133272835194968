import { useParams } from 'react-router-dom';
import { getKrisQuestion } from '../krisData';
import './skuld.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
export default function QuestionKris() {
	const { skuldId, questionId } = useParams();
	//console.log(skuldId);
	//console.log(questionId);

	const { question, answer, id, video, pdf, next } = getKrisQuestion({
		skuldId,
		questionId,
	});
	const arrow = <FontAwesomeIcon icon={faArrowRight} />;
	//console.log(id);
	return (
		<>
			<div key={id} className="course__main">
				{video ? (
					<video
						className="course__video"
						src={video}
						type="video/mp4"
						controls
						autoPlay
					/>
				) : (
					<h2>no video</h2>
				)}
				<div className="course__video-textbox">
					{next ? (
						<a className="course__video-textbox-link" href={next}>
							Nästa fråga {arrow}
						</a>
					) : (
						''
					)}
					<h2 className="course__video-textbox-h2">{question}</h2>
					{/* <p className="course__video-textbox-p">{answer}</p> */}
					{answer.map((e) => (
						<div key={Math.random().toString()}>{e}</div>
					))}

					{pdf ? (
						<a
							className="course__video-textbox-link"
							href={pdf}
							target="_blank"
							rel="noreferrer noopener"
						>
							Ladda ner PDF
						</a>
					) : (
						''
					)}
				</div>
			</div>
		</>
	);
}
