import { useParams, Routes, Route } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Profile from '../components/Profile';
import SideNav from '../components/SideNav';
import { getSkuld } from '../skulderData';
import Question from './question';
import './skuld.scss';

export default function Skuld() {
	const { skuldId } = useParams();

	const skuld = getSkuld(skuldId);
	//console.log(skuld.questions);
	//const navigate = useNavigate();
	//console.log(skuldId.indexOf);
	return (
		<>
			<Navbar />
			{/* <button onClick={() => navigate(-1)}>Tillbaka</button>

			<button onClick={() => navigate(+1)}>Nästa</button> */}
			<div className="course__container">
				<div className="course">
					<Routes className="course__main">
						<Route path=":questionId" element={<Question />} />{' '}
						<Route
							index
							element={
								<main>
									<Profile
										name={skuld.personName}
										about={skuld.about}
										image={skuld.image}
									/>
								</main>
							}
						/>
					</Routes>
					<SideNav
						title={skuld.name}
						questions={skuld.questions}
						id={skuld.key}
					/>
				</div>
			</div>
		</>
	);
}
//params använder url:en, url:en har vi bestämt i invoices.js

//TODO: fix udemy layout here, almost done
//TODO: load video from server done
//TODO: landing page when you arrive att eg malmö stad, prepared
//TODO: style question list, working on it
//TODO: when click on question scroll up, and load the video, not done
