import { render } from 'react-dom';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import App from './App';
import Skulder from './routes/skulder';
import Kriser from './routes/kriser';

import Skuld from './routes/skuld';
import Question from './routes/question';
import Kris from './routes/kris';
import FamiljeriForandring from './routes/familjeriforandring';

const rootElement = document.getElementById('root');
render(
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<App />} />

			<Route path="ekonomi/*" element={<Skulder />}></Route>
			<Route path="familjer-i-forandring" element={<FamiljeriForandring />} />
			<Route path="ekonomi/:skuldId/*" element={<Skuld />}>
				<Route
					path="ekonomi/:skuldId/:questionId/*"
					element={<Question />}
				></Route>
			</Route>
			<Route path="separationskrisen/*" element={<Kriser />}></Route>
			<Route path="separationskrisen/:skuldId/*" element={<Kris />}>
				<Route
					path="separationskrisen/:skuldId/:questionId/*"
					element={<Question />}
				></Route>
			</Route>

			<Route
				path="*"
				element={
					<main style={{ padding: '1rem' }}>
						<h2>Sidan hittas inte, fel 404</h2>
						<p>
							Sidan du letar efter finns inte längre. Kanske kan du återgå till{' '}
							<a href="/"> Startsidan</a> och se om du kan hitta det du letar
							efter. Eller så kan du försöka hitta det genom att använda
							sökformuläret nedan.
						</p>
					</main>
				}
			/>
			{/* </Route> */}
		</Routes>
		<Outlet />
	</BrowserRouter>,
	rootElement
);
//READ THIS
//https://ui.dev/react-router-nested-routes/
