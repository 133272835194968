const kriser = [
	{
		name: 'Anna Lidgard',
		id: 'anna-lidgard',
		introVideo: '/videos/AnnaIntro.mp4',
		personName: 'Anna Lidgard',
		next: '/separationskrisen/anna-lidgard/det-ar-normalt-att-reagera',
		image: '/images/bild_anna.jpg',
		about:
			'Kursledaren heter Anna Lidgard. Hon är jurist och har dessutom en fil. kand. i psykologi och pedagogik. Hon skilde sig själv när hennes barn var små och har personlig erfarenhet av att hantera en separation. Hennes främsta drivkraft är att barn till separerade föräldrar ska komma så oskadda genom separationen som det bara är möjligt.',
		topic: 'Så här fixar du separationskrisen ',

		answer: [
			'Har du separerat och känner att du skulle behöva praktiska råd och tips om hur du ska göra för att bearbeta alla förändringar och ta kloka beslut, så att du och dina barn kan gå vidare till ett bra och harmoniskt liv? Då är den här sidan för dig!',

			'Att bearbeta separationskrisen handlar inte bara om att prata av sig sin sorg och ilska, utan också om att ordna upp sitt liv på bästa sätt med just dina förutsättningar. Det gör du bland annat genom att planera för framtiden och sänka konfliktnivån mellan dig och din expartner för att skapa lugn och framtidstro.',

			'Under nio flikar behandlas olika sidor av separationsprocessen och hur du hanterar dem på ett kortfattat, lättillgängligt och konkret sätt. Kom ihåg att detta är grundläggande och allmänna råd och tips som är tänkta som ett stöd för dig som separerat. Du kan behöva söka mer detaljerad information och mer specifikt stöd. Tänk också på att vissa råd inte passar i alla situationer och under alla förutsättningar.',

			'Informationen presenteras både i text och i filmklipp. Under vissa flikar finns det dokument som du kan ladda ner och ta hjälp av. Du kan välja att gå direkt till det som intresserar just dig just för tillfället, men ta dig gärna tid att gå igenom alla avsnitt. De följer i en viss ordning och ger tillsammans en helhetsbild av vad du kan göra för att hjälpa dig själv. ',

			'Ett annat bra förslag är att delta i den kostnadsfria digitala utbildningen Familjer i Förändring för separerade föräldrar (klicka här). Syftet med den är att sänka konfliktnivån och förbättra samarbetet mellan separerade föräldrar för barnens skull.',

			'Lycka till med ditt nya liv! ',

			'Denna webbsida har, liksom den digitala utbildningen, tillkommit i ett samarbete mellan Sveriges Makalösa Föräldrar och Kom Igen AB.',

			<h3> Vad innehåller flikarna?</h3>,

			'1.	Det är normalt att reagera handlar om krisreaktioner på alla förändringar och krisens normala förlopp.',

			'2.	Katastrof eller möjlighet? utmanar dig att se på separationen som en möjlighet till utveckling och ett bättre liv.',

			'3.	Bearbeta separationskrisen betonar att det är nödvändigt att du bearbetar din sorg över alla förluster och inser vinsterna med separationen för att kunna gå vidare till ett nytt, mer harmoniskt liv.',

			'4.	Planera och ta genomtänkta beslut ger råd om hur du hanterar alla stora och små beslut som ska fattas i samband med separationen.',

			'5.	Ta hjälp ger dig tips om hur du kan ta hjälp av familj, vänner, organisationer och experter för att ordna upp ditt liv efter separationen.',

			'6.	Sänk konfliktnivån betonar hur viktigt det är att du slutar bråka med din expartner och sänker konfliktnivån för att du och barnen ska må bra.',

			'7.	Samarbeta med din expartner om barnen berättar hur du kan få till ett fungerande samföräldraskap med din expartner och varför det är nödvändigt.',

			'8.	Lär dig konstruktiv kommunikation lär dig att kommunicera med din expartner på ett fungerande sätt, bland annat med hjälp av jag-budskap.',

			'9.	Lär dig effektiv problemlösning handlar om hur du hanterar situationen när du och din expartner har svårt att fatta gemensamma beslut.',
		],
		questions: [
			{
				question: 'Det är normalt att reagera',
				id: 'det-ar-normalt-att-reagera',
				answer: [
					'En separation är en stor och omvälvande händelse för de flesta, särskilt om det finns barn inblandade. Det är helt normalt att bli stressad och reagera med starka känslor när så mycket förändras, ofta på kort tid. Du kanske känner dig fruktansvärt arg eller djupt skakad och sårad. Även den som ser på separationen med lättnad känner förmodligen ilska eller sorg.',

					'Det är också vanligt att känna skuld eller skam över uppbrottet och den skada som orsakats särskilt barnen. Då är det viktigt att komma ihåg att det inte är ett misslyckande att bryta upp ur ett dåligt eller till och med destruktivt förhållande. Det är istället att ta ansvar för sig själv och agera som en ansvarstagande vuxen – och som en god förebild – inför sina barn.',

					'För många leder hur som helst alla förändringar i samband med separationen till en kris. Kriser brukar ha ett visst förlopp, men processen kan ta olika lång tid för olika människor beroende på hur hårt separationen drabbat just dem. Var beredd på att humöret kan svänga fram och tillbaka. Det är helt normalt att må bra en dag och sedan må dåligt igen nästa dag. Det blir bättre efterhand, men räkna med att det kan att ta tid innan du är helt tillbaka på banan.',
					'Du måste sörja dina förluster och acceptera förändringarna för att kunna gå vidare till ett harmoniskt nytt liv! Kämpa på, så att du inte fastnar i depression eller ilska och bitterhet. Det skadar dig själv – men också dina barn – mer än du anar! Försök att ta kloka beslut och ordna upp ditt liv så gott det går. Se till att du får stöd när du behöver det. Sådant stärker! Läs mer om hur du gör bäst under de andra flikarna.',
					<h3>Lyssna på filmklippet och tänk på detta:</h3>,

					'•	Det är helt normalt att reagera starkt på alla förändringar i samband med en separation!',

					'•	Det är inget misslyckande, utan att ta ansvar för sig själv att bryta upp ur ett dåligt förhållande.',

					'•	Du måste sörja dina förluster och acceptera förändringarna för att kunna gå vidare till ett harmoniskt nytt liv!',

					'•	Du stärker ditt självförtroende och ökar din självkänsla genom att ta kloka beslut och ordna upp ditt liv!',

					'•	Se till att få den uppbackning du behöver. Det hjälper också!',
				],
				next: '/separationskrisen/anna-lidgard/katastrof-eller-mojlighet',
				video: '/videos/Anna1.mp4',
			},
			{
				question: 'Katastrof eller möjlighet?',
				id: 'katastrof-eller-mojlighet',
				answer: [
					'Oavsett hur jobbig just din separation har varit och är för dig är det nödvändigt att du bearbetar förändringarna så att du kan gå vidare och få tillbaka din självkänsla och din handlingskraft. Du måste få kontroll på dina känslor, sörja det du har förlorat och acceptera läget så att du kan komma igen med ny kraft och energi! Det är du värd!',

					'Det är ett känt faktum att kriser ger möjlighet till utveckling. Nu har du chansen att bygga upp det liv du verkligen vill ha och behöver för att må bra.',

					'Om du ser på separationen som en katastrof kan det vara klokt att fundera på vilka vinster du skulle kunna göra på sikt i alla fall. Ta hjälp av vinst- och förlust-listan under fliken Bearbeta separationskrisen. Gör också upp en framtidsplan för dig och dina barn, se fliken Planera och ta genomtänkta beslut.',

					'Om du har svårt att se just nu att något skulle kunna bli bättre måste du ha tålamod och ge dig själv tid att sörja det du förlorat. Stora förändringar tar tid att läka. Tänk bara på att det är superviktigt att du verkligen fortsätter att försöka tänka framåt och inte bara ser bakåt. Det leder ingenstans. Oavsett hur trista just dina omständigheter är finns det alltid något litet du kan göra för att förbättra din situation. Om inte annat ska du försöka att långsamt förändra din attityd till det som skett. Kanske var det inte bara dåligt? ',

					'Kom ihåg att det är otroligt dåligt för ditt eget välbefinnande och din egen hälsa att fortsätta leva i ilska eller sorg. Det är det också för dina barn. Du måste ta hand om dig själv och dem!',

					<h3>Lyssna på filmklippet och gör så här:</h3>,

					'•	Jobba aktivt med att ta dig ur krisen!',

					'•	Gör upp en framtidsplan – Hur vill jag att livet ska se ut om sex månader? Om ett år?',

					'•	Ta hjälp av vinst- och förlust-listan under fliken Bearbeta separationskrisen och skriv en egen lista – Vilka vinster har jag gjort eller kommer jag att göra?',

					'•	En framtidsplan kan kännas orealistisk just nu, men ger hopp och energi att gå framåt och ta kontroll över ditt liv.',

					'•	Kom ihåg att även små förändringar kan förbättra din situation och öka din självkänsla.',
				],
				next: '/separationskrisen/anna-lidgard/bearbeta-separationskrisen',
				video: '/videos/Anna2.mp4',
			},
			{
				question: 'Bearbeta separationskrisen',
				id: 'bearbeta-separationskrisen',
				answer: [
					'En separation orsakar ofta många och stora förändringar. De kan upplevas som bra eller dåliga, som vinster eller förluster. För att kunna gå vidare måste vi först sörja vad vi har förlorat och inse vinsterna. Därför måste du reda ut för dig själv vilka förluster, men även vinster, som just du har gjort på grund av separationen. ',

					'Det är ju klart att varje skilsmässa är unik. Hur vi upplever separationen beror på en massa saker, som exempelvis vem som tagit initiativet till uppbrottet, vår unika situation, personlighet och möjlighet att klara oss på egen hand, nya relationer osv, men det finns ändå många gemensamma nämnare. Våra förluster och vinster kan vara konkreta, som att ekonomin blir sämre, eller abstrakta, som känslan av frihet och oberoende. Det som är en förlust för en person kan vara en vinst för en annan.',

					'Särskilt i början innan du hunnit få ordning på kaoset kan många av förändringarna upplevas som förluster. Då är det extra viktigt att tänka på att även om allt känns fel och hemskt just nu, så kommer det att bli bättre. Känslan av frihet och att ha tagit ansvar för sitt och barnens liv kan vara viktiga vinster. Egentiden när barnen är hos expartnern kanske ger oss möjlighet att satsa mer på karriären och förbättra ekonomin. Den som hittar en ny partner får mer kärlek och närhet.',

					'Vilka vinster och förluster gjorde just du när du separerade? Använd den bifogade PDF:en Förluster och vinster vid en separation som bas för att skriva ihop din egen lista. Livet förändras ju hela tiden, så skriv gärna om listan då och då och fundera på vad som har förändrats.',

					'Kom ihåg att du gör det här för dig själv! Även om det kan vara svårt att inse det i början innebär separationen för många – i vart fall på sikt – en möjlighet till nystart och ett bättre, mer harmoniskt liv.',

					<h3>Lyssna på filmklippet och gör så här:</h3>,

					'•	Sätt ihop en lista över dina förluster och vinster i samband med separationen. Använd bilagda PDF Förluster och vinster vid en separation som bas. – Var ärlig. Hur ser din lista ut? Har du lagt till punkter på plus- eller minussidan? Överväger förlusterna eller finns det vinster också? Har listan förändrats sedan du separerade? Tror du att listan kommer att se annorlunda ut om ett år? Gör gärna om den då och då så att du ser vad som har förändrats.',

					'•	Kom ihåg att även om det var du som lämnade relationen har du gjort förluster som måste bearbetas. ',

					'•	Arbeta aktivt med att sörja det du förlorat och acceptera läget så att du kan gå vidare till ett bra liv.',

					'•	Gör upp en framtidsplan för dig själv och barnen och, se fliken Planera och ta genomtänkta beslut.',

					'•	Försök ordna upp livet rent praktiskt. Det ökar välbefinnandet.',

					'•	Gör tillsammans med expartnern upp ytterligare en plan för samarbetet om barnen, se fliken Planera och ta genomtänkta beslut. Ju mer ni kan komma överens om desto bättre.',

					'•	Sänk konfliktnivån med expartnern, se fliken Sänk konfliktnivån och jobba aktivt för att förbättra samarbetet om barnen, se fliken Samarbeta med din expartner om barnen. Det bidrar också till att du mår bättre!',

					'•	Ha tålamod med dig själv om det går långsamt och ta hjälp när du behöver det, se fliken Ta hjälp!',

					'Ladda ner bilagan - Förluster och vinster vid en separation (PDF) nedan. ',
				],
				next: '/separationskrisen/anna-lidgard/ta-hjalp',
				video: '/videos/Anna3.mp4',
				pdf: '/assets/Förluster och vinster vid en separation - FIF.pdf',
			},
			{
				question: 'Ta hjälp',
				id: 'ta-hjalp',
				answer: [
					'Det kan kännas väldigt ensamt efter en separation. Ens samtalspartner och förtrogna finns ju inte där längre. Samtidigt är det många svåra beslut som ska fattas och starka känslor som ska bearbetas.',

					'Ett bra sätt att komma vidare brukar vara att prata med sin familj och sina vänner. Om du inte vill belasta dem med dina problem så sök professionell hjälp med att bearbeta krisen. Låt bli att använda dina barn som samtalspartners. De har rätt till båda sina föräldrar och ska absolut inte behöva ta ställning för någon av er!',

					'Ett annat sätt att öka välbefinnandet är att ordna upp livet rent praktiskt. Ju fler saker du lyckas reda ut på ett bra sätt, desto bättre kommer du att må. Beröm dig själv varje gång du tagit minsta lilla steg mot ett fungerande liv. Även här kan du ta professionell hjälp. Fråga till exempel under samarbetssamtalen vilka möjligheter som finns till stöd och bidrag och vart eller till vem du ska vända dig. Du kan behöva diskutera din ekonomi med någon expert eller ta hjälp av en jurist för att få till en schysst bodelning. Bra tips är också att prata med jobbet om din arbetssituation och med skolpsykologen eller läraren om vad du kan göra för barnen. De kan också behöva stöd.',

					'Ring gärna Minds föräldralinje på telefon 020-85 20 00 om du behöver råd och stöd. Mind är en ideell förening som arbetar för psykisk hälsa. Sök även på mind.se hitta rätt kontakt för att hitta fler organisationer och jourer som kan hjälpa. Ideella organisationer, som Sveriges Makalösa Föräldrar, erbjuder ofta även möjligheter att umgås och utbyta erfarenheter med andra i samma situation. ',

					'Försök också att sänka konfliktnivån med din expartner. Det betyder massor för barnen, men också för dig själv, att du och din expartner kan prata och samarbeta med varandra på ett vettigt sätt. Gå gärna vår kostnadsfria digitala föräldrautbildning Familjer i Förändring (klicka här) för att få mer tips och råd om hur du undviker konflikter och förbättrar samarbetet med din expartner.',

					<h3>Lyssna på filmklippet och gör så här:</h3>,

					'•	Prata med släkt och vänner.',

					'•	Sök professionell hjälp med att bearbeta krisen om du behöver det.',

					'•	Låt bli att använda barnen som samtalspartners.',

					'•	Ring Minds föräldralinje på telefon 020-85 20 00 för att få råd och stöd.',

					'•	Sök även på www.mind.se hitta rätt kontakt för att hitta fler organisationer och jourer som kan hjälpa.',

					'•	Utnyttja den kunskap som finns inom exempelvis kommunen för att få tips och råd om stöd och bidrag.',

					'•	Prata med jobbet om din arbetssituation och med exempelvis skolan om barnen.',

					'•	Vänd dig till experter för att få råd om ekonomi och juridik om det behövs.',

					'•	Sänk konfliktnivån med expartnern, se fliken Sänk konfliktnivån, och förbättra samarbetet om barnen, se fliken Samarbeta med expartnern om barnen. För att få hjälp med det kan du delta i vår kostnadsfria digitala utbildning Familjer i Förändring för separerade föräldrar, länken hittar du i navigationsfältet längst upp på sidan.',

					'•	Underlätta kontakterna med expartnern genom att lära dig teknikerna för konstruktiv kommunikation, se fliken Lär dig konstruktiv kommunikation, och effektiv problemlösning, se fliken Lär dig effektiv problemlösning.',
				],
				next: '/separationskrisen/anna-lidgard/planera-och-ta-genomtankta-beslut',
				video: '/videos/Anna4.mp4',
			},
			{
				question: 'Planera och ta genomtänkta beslut',
				id: 'planera-och-ta-genomtankta-beslut',
				answer: [
					'I samband med en separation är det ofta massor av stora och små beslut som ska fattas på kort tid. Bodelning och flytt, din privatekonomi, vårdnaden om barnen och umgänget med dem, jobbsituationen är exempel på stora frågor som ibland måste hanteras mer eller mindre omedelbart. Dessutom ska allt detta ske när du och din expartner förmodligen är ovänner och ur balans.',

					'Om vi inte orkar se objektivt på situationen är det lätt att det blir fel. Sådana felbeslut kan förfölja en resten av livet, så gör inte ogenomtänkta och dumma saker i onödan. Gör istället upp en plan och följ råden nedan så kommer du att ta mer kloka beslut och må bättre. Det är ju ditt liv och din framtid det handlar om!',

					<h3>Lyssna på filmklippet och gör så här:</h3>,

					'•	Tänk så snart som möjligt igenom vad som är bäst för dig och barnen på lång sikt så att du har en övergripande och realistisk målbild – en plan. Du kan ändra den efterhand som läget förändras.',

					'•	Tänk efter noga och följ planen när du fattar beslut.',

					'•	Skjut om möjligt på stora beslut tills du är bättre i balans. Ibland kan tillfälliga lösningar vara bättre än snabba förändringar som inte går att ångra. Om du måste besluta något så tänk efter ordentligt före och ta så små steg som möjligt.',

					'•	Ta hjälp av andra, vänner, ideella organisationer och/eller experter, för att få råd om du känner dig osäker på vad som är bäst och hur du ska göra, se fliken Ta hjälp.',

					'•	Gör också upp en plan tillsammans med din expartner – så fort som möjligt och helst innan ni separerar – för hur ni ska hantera barnen och samarbetet kring dem efter separationen, se fliken Samarbeta med din expartner om barnen. Använd teknikerna för konstruktiv kommunikation, se Fliken Lär dig konstruktiv kommunikation, och effektiv problemlösning, se fliken Lär dig effektiv problemlösning. Börja med de lätta frågorna så att ni kommer igång. Ju mer ni kan komma överens om desto bättre för samarbetet och ditt välbefinnande.',

					'•	Jobba aktivt för att sänka konfliktnivån mellan dig och din expartner, se fliken Sänk konfliktnivån. Försök att vara så samarbetsvillig du bara orkar så att situationen inte låser sig och ni blir tvungna att gå till domstol med era problem. Det löser ingenting, utan gör oftast saken etter värre. ',

					'•	Samtidigt är det viktigt att du orkar stå upp för dig själv så att du inte går med på vad som helst. Kom dock ihåg att det inte leder någonvart att ställa till bråk. Var alltid vänlig och konstruktiv när du kommunicerar med din expartner för att inte trissa upp konflikten, se fliken Lär dig konstruktiv kommunikation.',
				],
				next: '/separationskrisen/anna-lidgard/sank-konfliktnivan',
				video: '/videos/Anna5.mp4',
			},
			{
				question: 'Sänk konfliktnivån',
				id: 'sank-konfliktnivan',
				answer: [
					'Konfliktnivån mellan par som separerar kan vara väldigt olika. Vissa förblir vänner efter separationen och har inga problem alls med att ses och samarbeta, medan andra inte tål att se varandra.',

					'Konfliktnivån brukar ligga som högst månaderna före och efter separationsbeslutet, det vill säga samtidigt som alla stora beslut ska fattas. Självklart har du rätt att vara arg och besviken på din expartner ett tag, men det är inte bra för din psykiska och fysiska hälsa att gå runt och vara arg eller deprimerad en längre tid. Den som är arg och bitter fattar också oftare ogenomtänkta beslut.',

					'Barnen är annan orsak till att ni måste anstränga er för att sänka konfliktnivån. Att undvika konflikter är bland det viktigaste du och din expartner kan göra för era barn. Massor av forskning visar entydigt att barn som utsätts för föräldrarnas konflikter under uppväxten får alla möjliga problem, inte bara direkt utan ofta för resten av livet. ',

					'Bråk trappar upp konfliktnivån ytterligare. Sådana gräl leder tyvärr ofta till att vi mår ännu sämre än förut. Dessutom kostar det massor av energi att vara arg, energi som du hade kunnat lägga på något bättre. Dig själv och barnen till exempel!',

					'Så vad kan du göra för att sänka konfliktnivån? Teknikerna nedan hjälper dig att bearbeta separationskrisen, så att du kan gå vidare och börja må bra igen.',

					<h3>Lyssna på filmklippet och gör så här:</h3>,

					'•	Identifiera vilken konfliktnivå du respektive din expartner ligger på idag med hjälp av PDF:en Bedöm konfliktnivån i samföräldraskapet. Den är hämtad från den kostnadsfria digitala utbildningen Familjer i Förändring för separerade föräldrar (klicka här) och gäller förutsättningarna för olika typer av samarbete om barnen, men den ger en god bild av var ni står. Läs den och följ instruktionerna.',

					'•	Ju högre konfliktpoäng någon av er får desto svårare blir det såklart att samarbeta och desto större skäl finns det att jobba aktivt med att sänka konfliktnivån mellan er. Över 18 poäng räknas som en hög konfliktnivå.',

					'•	Minska konflikterna mellan er genom att vara artig och positiv och använda andra tekniker för konstruktiv kommunikation i kontakterna med din expartner, se fliken Lär dig konstruktiv kommunikation.',

					'•	Undvik medvetet sådant som retar upp din expartner och skapar eller förstärker osämja. Bry dig inte om ifall din expartner fortsätter att försöka mucka gräl och bete sig illa. Om du inte svarar med samma mynt blir det i längden svårt för hen att fortsätta på samma sätt.',

					'•	Om ni inte kan ses utan att börja bråka, så kommunicera per mail och sms tills stämningen mellan er förbättrats.',

					'•	Har ni svårt att fatta gemensamma beslut kan ni pröva metoden för effektiv problemlösning, se fliken Lär dig effektiv problemlösning.',

					'Ladda ner PDF:en Bedöm konfliktnivån i samföräldraskapet nedan.',
				],
				next: '/separationskrisen/anna-lidgard/samarbeta-med-din-expartner-om-barnen',
				video: '/videos/Anna6.mp4',
				pdf: '/assets/Bedom konfliktnivan i samforaldraskapet - FIF.pdf',
			},
			{
				question: 'Samarbeta med din expartner om barnen',
				id: 'samarbeta-med-din-expartner-om-barnen',
				answer: [
					'För att du ska kunna bearbeta separationen och gå vidare till ett harmoniskt liv är det viktigt att du lyckas få till en fungerande samarbetsrelation med din expartner efter separationen. Vi kan kalla det samföräldraskap för att betona att samarbetet uteslutande gäller era gemensamma barn och vad som är bäst för dem. Ibland går det av olika skäl inte att ha någon kontakt med barnens andra förälder. Då får du försöka hitta lösningar som kompenserar för det, exempelvis genom tätare kontakter med nära och kära.',

					'Det finns många skäl till att det är bäst om du kan samarbeta med min expartner:',

					'•	Era gemensamma barn mår och anpassar sig mycket bättre efter separationen om ni kan undvika att bråka och istället samarbeta för deras bästa. Då kan de älska båda sina föräldrar utan att behöva känna att de är illojala mot någon av dem.',

					'•	Det blir lättare för dig som förälder om ni kan stödja varandra i föräldrarollen.',

					'•	Det är en fördel om ni kan träffa varandra utan att bråka när det behövs, exempelvis på barnens bemärkelsedagar. De flesta barn vill att ni båda är med vid sådana tillfällen. ',

					'•	Om ni har gemensam vårdnad måste ni ta gemensamt ansvar för barnens uppfostran och utveckling enligt lag. Även i annat fall har barn rätt till båda sina föräldrar om det inte strider mot deras bästa. Läs gärna barnkonventionen som är lag i Sverige. Just barnets bästa är grundpelaren i konventionen. Det är viktigt att ta hänsyn till barnens rättigheter när du och din expartner tar beslut som rör dem.',

					'•	Ett fungerande samföräldraskap bidrar till att ditt liv blir lugnare och mer harmoniskt.',

					'Men hur ska det gå till? ',

					<h3>Lyssna på filmklippet och gör så här:</h3>,

					'•	Försök att så snart som möjligt, helst innan ni separerar, göra upp en plan för hur samarbetet om barnen ska gå till. Ju mer ni kan komma överens om, desto smidigare och mindre konfliktfyllt blir samföräldraskapet. Börja med de lätta frågorna för att komma igång.',

					'•	Arbeta aktivt för att sänka konfliktnivån och förbättra samarbetet med din expartner.',

					'•	Använd teknikerna för konstruktiv kommunikation och effektiv problemlösning, se flikarna Lär dig konstruktiv kommunikation och Lär dig effektiv problemlösning. Då är chansen stor att kontakten förbättras efterhand.',

					'•	Delta i den kostnadsfria digitala utbildningen Familjer i Förändring för separerade föräldrar (klicka här) för att lära dig mer om hur du bygger upp ett gott samföräldraskap. Kursen är uppdelad på 8 avsnitt om cirka en halvtimme.',
				],
				next: '/separationskrisen/anna-lidgard/lar-dig-konstruktiv-kommunikation',
				video: '/videos/Anna7.mp4',
			},
			{
				question: 'Lär dig konstruktiv kommunikation',
				id: 'lar-dig-konstruktiv-kommunikation',
				answer: [
					'Samspelet med expartnern fungerar så mycket bättre om ni kan kommunicera med varandra på ett artigt och trevligt sätt. Genom att ni använder vänliga, eller i vart fall neutrala, ord och uttryck och beter er på ett schysst sätt blir stämningen mindre laddad och det gör det mycket lättare att samarbeta och ta beslut tillsammans.',

					<h3>Så här kommunicerar du på ett konstruktivt sätt:</h3>,

					'•	Använd ord som uttrycker positiva känslor, såsom glad, tacksam, lättad etc. ',

					'•	Använd uttryck som vi använder när vi vill vara artiga, såsom ”Det vore trevligt om…” eller ”Tror du att det skulle fungera om…” i kommunikationen med din expartner. ',

					'•	Undvik ord och beteenden som utlöser negativa reaktioner.',

					'•	Undvik också att ”läsa” din expartners tankar eller dra förhastade slutsatser om hen. Det är väldigt vanligt att vi tolkar in mer i andra personers agerande och kommentarer än vad som faktiskt gäller, särskilt om vi har en negativ inställning från början. Fråga istället om du inte förstår vad hen menar.',

					'•	Be om ursäkt när det är motiverat. De flesta reagerar väldigt positivt om vi kan erkänna att vi gjort fel. ',

					'•	Ge beröm när det är motiverat. Det stärker er relation även om det bara handlar om småsaker. ”Det där gjorde du bra!” känns alltid trevligt att få höra.',

					'•	Använd absolut inte du-budskap, som ”Du är en idiot”, när du blir arg! Då kritiserar du din expartner som person och då är risken stor att hen bara blir arg och går till motangrepp, slutar lyssna och vägrar samarbeta.',

					'•	Använd istället jag-budskap när du vill förklara att du är missnöjd med något din expartner säger eller gör och be om förändring. Då blir det lättare för din expartner att ta till sig vad du vill förmedla och göra dig till lags. Läs mer om tekniken nedan.',

					<h3>Formeln för jag-budskap</h3>,

					'Efter en separation är det extra stor risk att det uppstår situationer när vi blir irriterade eller ledsna över vad expartnern säger eller gör. Det är självklart mycket viktigt att du är rak och uppriktig med dina känslor gentemot din expartner, men det är minst lika viktigt att du uttrycker dina negativa känslor på ett neutralt sätt utan att bli aggressiv eller göra personliga påhopp. Jag-budskap är ett konstruktivt sätt att berätta vad som stör en och be om förändring utan att göra den andra personen arg. Det kallas jag-budskap för att det fokuserar på dig och vad du känner. Då ligger fokus på dig och inte på den du talar med och då blir det lättare för den andra personen att ta till sig vad du vill förmedla.',

					'Om du inte redan kan tekniken, så lär dig att använda jag-budskap! Så här fungerar det:',

					'•	Berätta på ett neutralt och konstruktivt sätt',

					'•	att du är missnöjd med något, ',

					'•	vad det är den andra personen säger eller gör som stör dig,',

					'•	varför det får dig att bli arg, ledsen etc. och',

					'•	vad du vill att den andra personen ska göra istället.',

					'•	Erbjud dig eventuellt att göra något i utbyte.',

					'•	Fråga om den andra personen tycker att förslaget är godtagbart eller vill komma med ett annat förslag.',

					'Ladda ner och använd den bifogade PDF:en Formeln för jag-budskap som mall och pröva att göra ett eget exempel.',
				],
				next: '/separationskrisen/anna-lidgard/lar-dig-effektiv-problemlosning',
				video: '/videos/Anna8.mp4',
				pdf: '/assets/Formeln for jag-budskap - FIF.pdf',
			},
			{
				question: 'Lär dig effektiv problemlösning',
				id: 'lar-dig-effektiv-problemlosning',
				answer: [
					'I samband med en separation är det massor av stora och små saker som du och din expartner måste komma överens om. Även senare måste ni fortsätta att samarbeta om barnen. Det är mer än viktigt för ditt välbefinnande och din återhämtning efter separationen att det samarbetet fungerar smidigt. Men hur samarbetsvilliga vi än är händer det tyvärr har vi planer och åsikter som krockar och inte går att förena. Särskilt om toleransnivån och kompromissviljan är låg kan det lätt uppstå allvarliga konflikter i sådana situationer. I sådana lägen är det så mycket bättre om ni kan hålla er lugna och försöka diskutera fram en lösning. Om ni inte vill träffas eller prata med varandra i telefon kan ni ta diskussionen per mail eller sms.',

					'Metoden för effektiv problemlösning innehåller fem steg. ',

					<h3>Lyssna på filmklippet och gör så här:</h3>,

					'•	Identifiera och sätta namn på problemet. Fokusera på en fråga i taget. ',

					'•	Vänd och vrid på problemet och bolla fram olika förslag till lösning. Förslagen måste naturligtvis vara realistiska, men tänk brett. ',

					'•	Utvärdera förslagen när ni fått ihop minst tre tänkbara alternativ. Stryk alternativ som inte fungerar för er båda. ',

					'•	Välj tillsammans det bästa alternativet. Bestäm vem som ska göra vad och när. Det underlättar och förebygger ytterligare diskussioner. Glöm inte att tacka varandra för det goda samarbetet.',

					'•	Sätt planen i verket. Försök vara samarbetsvillig och flexibel.',

					<h3>Fler tips:</h3>,

					'•	Använd teknikerna för konstruktiv kommunikation när ni förhandlar om en lösning på olika problem. Var vänlig och artig och använd jag-budskap så att diskussionen inte spårar ur.',

					'•	Håll er till saken och ta time-out om någon av er tappar kontrollen över sina känslor.',

					'•	Skjut om möjligt på stora och viktiga beslut tills du är bättre i balans. Ibland kan tillfälliga lösningar vara bättre än snabba förändringar som inte går att ångra.',

					'•	Om ni måste fatta något viktigt beslut så kom väl förberedd till diskussionen. Tänk noga igenom saken och gör nödvändiga efterforskningar i förväg.',

					'•	Viktiga frågor kan ibland behöva bordläggas på grund av att det behövs mer information eller att ni inte kan enas. Bestäm i så fall en ny tid då ni ska diskutera frågan igen. ',

					'•	Kom ihåg att tålamod och uthållighet lönar sig. Ge inte upp!',

					'Läs exemplet på hur ni ska göra i den bifogade PDF:en Metoden för effektiv problemlösning. Det är hämtat från den kostnadsfria digitala utbildningen Familjer i Förändring för separerade föräldrar (klicka här) och gäller ett praktiskt problem i samföräldraskapet, men samma teknik kan användas på alla problem som behöver lösas. Pröva att göra ett eget exempel!',

					' Ladda ner PDF:en Metoden för effektiv problemlösning nedan.',
				],
				video: '/videos/Anna9.mp4',
				pdf: '/assets/Metoden för effektiv problemlosning - FIF.pdf',
			},
		],
	},
];

export function getKriser() {
	return kriser;
}

export function getKrisUri(uri) {
	return kriser.find((skuld) => skuld.uri === uri);
}
export function getKris(skuldId) {
	return kriser.find(({ id }) => id === skuldId);
}
export function getKrisQuestion({ skuldId, questionId }) {
	return kriser
		.find(({ id }) => id === skuldId)
		.questions.find(({ id }) => id === questionId);
}
