import { NavLink } from 'react-router-dom';

const SideNav = (props) => {
	return (
		<nav className="course__ul">
			<h2 className="title-h2">Tips från: {props.title}</h2>
			{props.questions.map((sub) => (
				<NavLink
					className={(navData) => (navData.isActive ? 'course__link' : '')}
					key={sub.id}
					to={sub.id}
					role="navigation"
				>
					<div className="course__li" key={sub.id}>
						{sub.question}
					</div>
				</NavLink>
			))}
		</nav>
	);
};

export default SideNav;
