import Navbar from '../components/Navbar';
import './staticPage.scss';
import { getKris } from '../krisData';
import Profile from '../components/Profile';

export default function FamiljeriForandring() {
	const kris = getKris('anna-lidgard');
	//console.log(kris.name);
	return (
		<>
			<Navbar />
			<div className="pageContainer">
				<h1 className="pageContainer__h1">
					VARMT VÄLKOMMEN TILL VÅR DIGITALA UTBILDNING FAMILJER I FÖRÄNDRING FÖR
					SEPARERADE FÖRÄLDRAR!
				</h1>
				<p className="pageContainer__p">
					Familjer i Förändring är en digital utbildning i 8 avsnitt som främst
					riktar sig till separerade föräldrar med minderåriga barn. Kursen ger
					dig stöd och verktyg att hantera separationen och den nya situationen
					på bästa sätt, så att du och dina barn kan gå vidare och leva bra och
					harmoniska liv.
				</p>{' '}
				<h3 className="pageContainer__h3">
					<br></br>
					För att gå kursen{' '}
					<a
						className="pageContainer__a"
						href="https://utbildning.makalosa.org/moodle"
					>
						klicka här{' '}
					</a>{' '}
					för att registrera dig.
				</h3>
				<div className="pageContainer__videogroup">
					<video
						title="Anna Lidgard"
						className="pageContainer__videogroup-ifram"
						src="/videos/AnnaFam1.mp4"
						type="video/mp4"
						controls
					></video>

					<video
						title="Anna Lidgard"
						className="pageContainer__videogroup-ifram"
						src="/videos/AnnaFam2.mp4"
						type="video/mp4"
						controls
					></video>
				</div>
				<ul className="list">
					<h3 className="list__header">
						Kursen innehåller bland annat konkreta verktyg och råd för hur du:
					</h3>
					<li className="list__item">
						stärker kontakten och relationen mellan dig och dina barn
					</li>
					<li className="list__item">
						bygger ett fungerande samföräldraskap med din expartner
					</li>
					<li className="list__item">
						kommunicerar med din expartner på ett konstruktivt sätt
					</li>
					<li className="list__item">
						undviker konflikter och löser problem i samföräldraskapet
					</li>
					<li className="list__item">
						hanterar nya kärleksrelationer på bästa sätt
					</li>
				</ul>
				<h2 className="pageContainer__h1">
					Barn mår dåligt av föräldrarnas bråk
				</h2>
				<p className="pageContainer__p">
					Efter en separation kan konfliktnivån mellan separerade föräldrar vara
					hög. Föräldrarna är de viktigaste personerna i barnens liv och deras
					bråk drabbar barnen hårt och får tyvärr ofta långsiktigt negativa
					konsekvenser för dem. Därför är det extra viktigt att separerade
					föräldrar sänker konfliktnivån mellan sig och samarbetar om barnen på
					ett konstruktivt sätt. Familjer i Förändring hjälper dig att uppnå
					dessa mål.
				</p>
				<h2 className="pageContainer__h1">
					Familjer i Förändring - digital utbildning för separerade föräldrar
				</h2>
				<p className="pageContainer__p">
					Denna kostnadsfria digitala utbildning för separerade föräldrar med
					minderåriga barn bygger på kursprogrammet Familjer i Förändring, som
					är en anpassad version av ett väletablerat amerikanskt
					utbildningsprogram för separerade föräldrar. Vår utbildning stärker
					föräldraförmågan och relationen mellan separerade föräldrar och barn.
					Dessutom förbättrar den deltagarnas förmåga att kommunicera med
					expartnern, att undvika och lösa konflikter och att samarbeta om
					barnen på ett konstruktivt sätt.
				</p>
				<Profile name={kris.name} image={kris.image} about={kris.about} />
			</div>
		</>
	);
}
