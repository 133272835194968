const skulder = [
	{
		name: 'Budget och skuldrådgivare',
		id: 'kommunal-skuldradgivning',
		about: 'Tomas Sandin arbetar som Budget och Skuldrådgivare för Malmö Stad',
		personName: 'Tomas Sandin',
		image: '/images/tomas_bild.jpg',
		questions: [
			{
				question: 'Vad är en budget och skuldrådgivare?',
				id: 'vad-ar-en-budget-och-skuldradgivare',
				answer: ['Tomas Sandin förklarar vad är en budget och skuldrådgivare'],
				video: '/videos/tomas1.mp4',
				next: '/ekonomi/kommunal-skuldradgivning/vad-kan-jag-fa-hjalp-med-av-en-budget-och-skuldradgivare',
			},
			{
				question: 'Vad kan jag få hjälp med av en budget och skuldrådgivare?',
				id: 'vad-kan-jag-fa-hjalp-med-av-en-budget-och-skuldradgivare',
				answer: [
					'Tomas Sandin förklarar vad du kan få hjälp med av en budget och skuldrådgivare',
				],
				video: '/videos/tomas2.mp4',
				next: '/ekonomi/kommunal-skuldradgivning/nar-kan-jag-ta-kontakt-med-en-budget-och-skuldradgivare',
			},
			{
				question: 'När kan jag ta kontakt med en budget och skuldrådgivare?',
				id: 'nar-kan-jag-ta-kontakt-med-en-budget-och-skuldradgivare',
				answer: [
					'Tomas Sandin förklarar när du kan ta kontakt med en budget och skuldrådgivare',
				],
				video: '/videos/tomas3.mp4',
				next: '/ekonomi/kommunal-skuldradgivning/hur-kommer-jag-i-kontakt-med-en-budget-och-skuldradgivare',
			},
			{
				question: 'Hur kommer jag i kontakt med en budget och skuldrådgivare?',
				id: 'hur-kommer-jag-i-kontakt-med-en-budget-och-skuldradgivare',
				answer: [
					'Tomas Sandin förklarar hur du kan ta kontakt med en budget och skuldrådgivare',
				],
				video: '/videos/tomas4.mp4',
			},
		],
	},
	{
		name: 'Kronofogden',
		id: 'kronofogden',
		about:
			'Elisabet arbetar som kommunikatör i den förebyggande verksamheten hos Kronofogden',
		personName: 'Elisabeth Hultman',
		image: '/images/elisabeth_bild3.jpg',

		questions: [
			{
				question:
					'Jag står inför eller är mitt uppe i en separation,	vad ska jag tänka på gällande min ekonomi?',
				id: 'jag-star-infor-eller-ar-mitt-uppe-i-en-separation-vad-ska-jag-tanka-pa-gallande-min-ekonomi',

				video: '/videos/elisabeth1.mp4',
				next: '/ekonomi/kronofogden/hur-kan-jag-prata-om-ekonomisk-oro-med-mitt-barn',
			},
			{
				question: 'Hur kan jag prata om ekonomisk oro med mitt barn?',
				id: 'hur-kan-jag-prata-om-ekonomisk-oro-med-mitt-barn',

				video: '/videos/elisabeth2.mp4',
				next: '/ekonomi/kronofogden/hur-kan-jag-prata-om-pengar-med-mitt-barn',
			},
			{
				question: 'Hur kan jag prata om pengar med mitt barn?',
				id: 'hur-kan-jag-prata-om-pengar-med-mitt-barn',

				video: '/videos/elisabeth3.mp4',
				next: '/ekonomi/kronofogden/vad-innebar-en-skuldsanering',
			},
			{
				question: 'Vad innebär en skuldsanering?',
				id: 'vad-innebar-en-skuldsanering',

				video: '/videos/elisabeth4.mp4',
				next: '/ekonomi/kronofogden/vad-innebar-en-utmatning',
			},
			{
				question: 'Vad innebär en utmätning?',
				id: 'vad-innebar-en-utmatning',

				video: '/videos/elisabeth5.mp4',
				next: '/ekonomi/kronofogden/vad-ar-kronofogdens-uppdrag',
			},
			{
				question: 'Vad är Kronofogdens uppdrag?',
				id: 'vad-ar-kronofogdens-uppdrag',

				video: '/videos/elisabeth6.mp4',
				next: '/ekonomi/kronofogden/vad-hander-med-mina-skulder-om-min-familjesituation-forandras',
			},
			{
				question:
					'Vad händer med mina skulder om min familjesituation förändras?',
				id: 'vad-hander-med-mina-skulder-om-min-familjesituation-forandras',

				video: '/videos/elisabeth7.mp4',
				next: '/ekonomi/kronofogden/nar-vi-separerade-gjorde-vi-en-overenskommelse-om-vem-som-betalar-vad-for-barnen-kan-jag-ansoka-om-indrivning-via-kronofogden-om-min-fore-detta-partner-inte-betalar',
			},
			{
				question:
					'När vi separerade gjorde vi en överenskommelse om vem som betalar vad för barnen, kan jag ansöka om indrivning via Kronofogden om min före detta partner inte betalar? ',
				id: 'nar-vi-separerade-gjorde-vi-en-overenskommelse-om-vem-som-betalar-vad-for-barnen-kan-jag-ansoka-om-indrivning-via-kronofogden-om-min-fore-detta-partner-inte-betalar',

				video: '/videos/elisabeth8.mp4',
			},
		],
	},
];

export function getSkulder() {
	return skulder;
}
// export function getSkuld(id) {
// 	return skulder.find((skuld) => skuld.id === id);
// }
export function getSkuldUri(uri) {
	return skulder.find((skuld) => skuld.uri === uri);
}
export function getSkuld(skuldId) {
	return skulder.find(({ id }) => id === skuldId);
}
export function getQuestion({ skuldId, questionId }) {
	//console.log('hej');
	//console.log(questionId);
	return skulder
		.find(({ id }) => id === skuldId)
		.questions.find(({ id }) => id === questionId);
}
