import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCoins,
	//faGavel,
	faBrain,
	faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';

import React from 'react';
//import '../App.css';

import './HeroSection.scss';
import NavbarLogo from './NavbarLogo';
import { Link } from 'react-router-dom';

function HeroSection() {
	const coins = <FontAwesomeIcon icon={faCoins} />;
	//const law = <FontAwesomeIcon icon={faGavel} />;
	const graduation = <FontAwesomeIcon icon={faGraduationCap} />;
	const mentalHealth = <FontAwesomeIcon icon={faBrain} />;
	return (
		<header className="header">
			<div className="bg-video">
				<video className="bg-video__content" autoPlay muted loop>
					<source src="videos/headVideo.mp4" type="video/mp4" />
					Your browser is not supported!
				</video>
			</div>
			<div className="header__logo">
				<NavbarLogo />
				<a href="https://makalosa.org" className="navbar-makalosa">
					<img
						src="/images/makalosa_logga.png"
						alt="En del av Sveriges Makalösa Föräldrar"
						className="navbar-makalosa-img"
					></img>
				</a>
			</div>
			<div className="header__linkgroup">
				<Link className="header__linkgroup-link" to="/ekonomi">
					{coins}
					Ekonomi
				</Link>
				{/* <Link className="header__linkgroup-link" to="/">
					{law} Juridik
				</Link> */}
				<Link to="/separationskrisen" className="header__linkgroup-link">
					{mentalHealth} Separationskrisen
				</Link>
			</div>
			<div className=" header__linkgroup header__linkgroup-2">
				<Link to="/familjer-i-forandring" className="header__linkgroup-link">
					{graduation} Kursen Familjer<br></br> i förändring
				</Link>
			</div>
		</header>
	);
}

export default HeroSection;

//style navbar logo
