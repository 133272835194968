import { useParams } from 'react-router-dom';
import { getQuestion } from '../skulderData';
import './skuld.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
export default function Question() {
	const { skuldId, questionId } = useParams();
	//console.log(skuldId);
	//console.log(questionId);
	const { question, answer, id, video, next } = getQuestion({
		skuldId,
		questionId,
	});
	const arrow = <FontAwesomeIcon icon={faArrowRight} />;
	return (
		<>
			<div key={id} className="course__main">
				{video ? (
					<video
						className="course__video"
						src={video}
						type="video/mp4"
						controls
						autoPlay
					/>
				) : (
					<h2>no video</h2>
				)}

				<div className="course__video-textbox">
					{next ? (
						<a className="course__video-textbox-link" href={next}>
							Nästa fråga {arrow}
						</a>
					) : (
						''
					)}
					<h2 className="course__video-textbox-h2">{question}</h2>
					<div>{answer}</div>
				</div>
			</div>
		</>
	);
}
