import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.scss';

function NavbarLogo() {
	return (
		<Link to="/" className="navbar-logo">
			<h1 className="navbar-logo-h">Separationskollen</h1>
			<p className="navbar-logo-p">- Din guide vid din separation</p>
		</Link>
	);
}
export default NavbarLogo;
{
	/* <img
		src="/images/makalosa_logga.png"
		alt="Sveriges Makalösa Föräldrar"
		className="navbar-logo-img"
	></img> */
}
